export const NewsFilterMixin = {
  props: {
    date: {
      type: Object,
      default() {
        return {
          min: '01.08.2018',
          max: '20.08.2018'
        };
      }
    }
  },
  data() {
    return {
      publishDate: {
        dateFrom: this.date.min || undefined,
        dateTo: this.date.max || undefined
      },
      openedFilter: false,
      wrongPeriod: false,
      invalidDate: false
    };
  },
  methods: {
    toggleFilter() {
      this.openedFilter = !this.openedFilter;
    },

    onPublishDate($event) {
      this.publishDate = {
        dateFrom: $event.dateFrom.length > 0 ? $event.dateFrom : undefined,
        dateTo: $event.dateTo.length > 0 ? $event.dateTo : undefined
      };
    },

    onInvalidDate(value) {
      this.invalidDate = value;
    },

    checkForm() {
      if (this.$refs.submitButton) {
        this.$refs.submitButton.$el.focus();
      }

      this.wrongPeriod = this.invalidDate;
      this.$emit('invalidData', this.invalidDate);

      if (this.invalidDate) {
        return;
      }

      const params = {
        rubric: this.rubricValue !== 'all' ? this.rubricValue : undefined,
        ...this.publishDate
      };

      this.$emit('onFilter', params);
    }
  }
};
