import { guardUnspecified } from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../mixins/inject-styles';
import { JtnUiImage, AspectRatio } from '../../jtn-ui-image';

import styles from './jtn-ui-search-archive-item.styles.scss?module';

type Image = {
  src: string;
  hasCommercialLabel: boolean;
  sources: {
    srcset: string;
    sizes?: string;
    type?: string;
    media?: string;
  }[];
  decoding?: 'auto' | 'sync' | 'async';
  loading?: 'lazy' | 'eager';
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiSearchArchiveItem',
  props: {
    url: {
      type: String,
      default: ''
    },
    targetUrl: {
      type: String as () => '_self' | '_blank',
      default: '_self'
    },
    urlTitle: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    },
    subheader: {
      type: String,
      default: ''
    },
    image: {
      type: Object as () => Image,
      default: null
    },
    hasVisitedStyles: {
      type: Boolean,
      default: true
    },
    hasVideoMark: {
      type: Boolean,
      default: false
    },
    isMobileOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    markVideoSize(): number {
      return this.isMobileOnly ? 32 : 20;
    },
    hasImage() {
      return guardUnspecified(this.image);
    },
    hasCommercialLabel() {
      return guardUnspecified(this.image) && this.image.hasCommercialLabel;
    },
    hasVideoIcon() {
      return this.hasVideoMark && !this.hasCommercialLabel;
    }
  },
  render() {
    const attributes = {
      attrs: this.$attrs
    };

    const listeners = {
      on: this.$listeners
    };

    return (
      <article
        class={[styles.searchRecordItem, this.hasVideoIcon ? styles.hasVideos : '']}
        {...attributes}
        data-test="archive-record-item"
      >
        <a
          class={styles.recordLeft}
          href={this.url}
          target={this.targetUrl}
          data-test="archive-record-image"
          {...listeners}
        >
          {this.hasImage && (
            <JtnUiImage
              key={this.image.src}
              class={styles.img}
              aspectRatio={AspectRatio.Wide}
              hasCommercialLabel={this.image.hasCommercialLabel}
              src={this.image.src}
              sources={this.image.sources}
            />
          )}

          {this.hasVideoIcon && (
            <div class={styles.markVideo}>
              <svg width={this.markVideoSize} height={this.markVideoSize}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-video-outlined"></use>
              </svg>
            </div>
          )}
        </a>

        <div class={styles.recordRight}>
          <div class={styles.group}>
            <div class={styles.rubrics} data-test="archive-record-rubrics">
              {this.$slots.rubrics}
            </div>
            <div class={styles.theme} data-test="archive-record-theme">
              {this.$slots.theme}
            </div>
            <div class={styles.format} data-test="archive-record-format">
              {this.$slots.format}
            </div>
          </div>

          <div class={styles.recordHeaders}>
            <h2 class={[styles.header, this.hasVisitedStyles ? '' : styles.noVisited]}>
              <a
                href={this.url}
                title={this.urlTitle}
                target={this.targetUrl}
                data-test="archive-record-header"
                domPropsInnerHTML={this.header}
                {...listeners}
              />
            </h2>

            {this.subheader.length > 0 && (
              <div class={styles.subheader}>
                <a
                  href={this.url}
                  title={this.urlTitle}
                  target={this.targetUrl}
                  data-test="archive-record-subheader"
                  domPropsInnerHTML={this.subheader}
                  {...listeners}
                />
              </div>
            )}
          </div>

          <div class={styles.bottomBlock}>
            <div class={styles.dateAndStats}>
              <div
                class={[styles.publishDate, styles.bottomItem]}
                data-test="archive-record-publish-date"
              >
                {this.$slots.date}
              </div>
              <div
                class={[styles.stats, styles.bottomItem]}
                data-test="archive-record-stats"
              >
                {this.$slots.stats}
              </div>
            </div>
            <div
              class={[styles.removeFromBrowsingHistoryBtn, styles.bottomItem]}
              data-test="archive-record-browsing-history"
            >
              {this.$slots.removeFromBrowsingHistoryBtn}
            </div>
            <div
              class={[styles.bookmark, styles.bottomItem]}
              data-test="archive-record-bookmark"
            >
              {this.$slots.bookmark}
            </div>
          </div>
        </div>
      </article>
    );
  }
});
