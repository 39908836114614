<template>
  <div :class="$style.searchForm">
    <form :class="$style.form" autocomplete="off" @submit.prevent="checkForm">
      <!-- TODO: перенести компонент в jtn-ui -->
      <div :class="$style.searchBox">
        <svg :class="$style.searchIcon" :width="24" :height="24">
          <use xlink:href="jtn-critical-search"></use>
        </svg>
        <input
          ref="inputSearch"
          :value="searchValue"
          :class="$style.inputSearch"
          name="query"
          @input="onChangeQuery"
        />
        <jtn-ui-btn-icon
          v-if="searchValue.length > 0"
          :class="$style.resetBtn"
          btn-type="button-other-transparent"
          type="button"
          :size="15"
          @click="resetForm()"
        >
          <svg :width="15" :height="15">
            <use xlink:href="jtn-critical-close"></use>
          </svg>
        </jtn-ui-btn-icon>
      </div>

      <button :class="$style.hackIos" ref="submitButton" type="submit" />

      <div :class="$style.result">
        <jtn-ui-typography
          v-if="resultMessage.length > 0"
          :class="$style.resultMessage"
          tag="span"
          type="font-photoday-type-1"
        >
          {{ resultMessage }}
        </jtn-ui-typography>

        <jtn-ui-typography
          :class="[openedFilter ? $style.opened : '', $style.openBtn]"
          tag="a"
          type="font-vijet-title"
          @click.prevent="toggleFilter"
        >
          Уточнить запрос
          <svg
            :class="$style.icon"
            :width="22"
            :height="22"
            :stroke-width="2">
            <use xlink:href="jtn-critical-arrow-down"></use>
          </svg>
        </jtn-ui-typography>
      </div>

      <div :class="[openedFilter ? $style.opened : '', $style.filter]">
        <jtn-ui-datepicker
          v-if="isMounted"
          v-show="isDatepickerMounted"
          :class="[$style.filterItem, $style.dateRange]"
          :dates="[date.min, date.max]"
          :isRange="true"
          placeholder="Период"
          @mounted="setIsDatepickerMounted"
          @changedPeriod="setPublishDate($event)"
        />
        <div
          v-show="!isDatepickerMounted"
          :class="[$style.filterItem, $style.placeholderDatepicker]"
        />
        <jtn-ui-select
          :class="[$style.filterItem, $style.formSelect]"
          :selected="sortValue"
          :options="optionsSort"
          @change="onSort"
        />

        <jtn-ui-btn :class="$style.submitBtn" type="submit"> Применить </jtn-ui-btn>
      </div>
    </form>
  </div>
</template>

<script>
  import { JtnUiDatepicker } from '@jtn-ui/components/datepicker';

  import { JtnUiBtnIcon, JtnUiSelect, JtnUiBtn, JtnUiTypography } from '@jtnews/jtn-ui';
  import { HTML_TAGS_REGEX } from '@jtnews/shared/data';

  import { NewsFilterMixin } from '../../../../shared/mixins/news-filter.mixin';

  export default {
    name: 'SearchForm',
    components: {
      JtnUiBtnIcon,
      JtnUiDatepicker,
      JtnUiTypography,
      JtnUiBtn,
      JtnUiSelect
    },
    mixins: [NewsFilterMixin],
    props: {
      count: {
        type: Number,
        default: 0
      },
      query: {
        type: String,
        default: ''
      },
      sort: {
        type: String,
        default: 'date'
      }
    },
    data() {
      return {
        wrongQuery: false,
        isMounted: false,
        isDatepickerMounted: false,
        sortValue: this.sort,
        searchValue: '',
        optionsSort: [
          {
            value: 'date',
            name: 'Сначала новые'
          },
          {
            value: '-date',
            name: 'Сначала старые'
          },
          {
            value: 'weight',
            name: 'По релевантности'
          }
        ]
      };
    },
    computed: {
      resultMessage() {
        if (this.wrongPeriod) {
          this.$emit('invalidData', true);
          return 'Задан неверный период времени';
        }

        if (this.wrongQuery || this.query.length < 3) {
          this.$emit('invalidData', true);
          return 'Ключевое слово не может быть короче 3-х символов';
        }

        if (this.query.length > 2 && this.count > 0) {
          this.$emit('invalidData', false);
          return `По запросу «${this.query}» найдено ${this.formatCount} совпадений`;
        }

        if (this.invalidQuery || this.query.match(HTML_TAGS_REGEX) !== null) {
          this.$emit('invalidData', false);
          return `Запрос содержит недопустимые символы`;
        }

        if (this.query.length > 2 && this.count === 0) {
          this.$emit('invalidData', false);
          return `По запросу «${this.query}» ничего не найдено`;
        }

        this.$emit('invalidData', false);
        return '';
      },
      invalidQuery() {
        return this.searchValue.match(HTML_TAGS_REGEX) !== null;
      },
      formatCount() {
        return this.count.toLocaleString('ru');
      }
    },
    watch: {
      query() {
        this.searchValue = this.query || '';
      },
      sort() {
        this.sortValue = this.sort;
      }
    },
    mounted() {
      this.isMounted = true;
      this.searchValue = this.query;
    },
    methods: {
      onChangeQuery($event) {
        this.searchValue = $event.target.value;
      },
      onSort($event) {
        this.sortValue = $event.target.value;
      },
      checkForm() {
        // для того, чтобы скрыть клавиатуру на девайсах
        this.$refs.inputSearch.blur();
        this.$refs.submitButton.focus();

        this.wrongPeriod = this.invalidDate;
        this.wrongQuery = this.searchValue.length < 3;

        if (this.invalidDate || this.wrongQuery || this.invalidQuery) {
          return;
        }

        const params = {
          text: this.searchValue,
          sort: this.sortValue,
          ...this.publishDate
        };

        this.$emit('onSearch', params);
      },
      resetForm() {
        this.searchValue = '';
        this.$refs.inputSearch.focus();
      },
      setPublishDate(dates) {
        this.publishDate = {
          dateFrom: dates[0] || undefined,
          dateTo: dates[1] || undefined
        };
      },
      setIsDatepickerMounted() {
        this.isDatepickerMounted = true;
      }
    }
  };
</script>

<style lang="scss" module>
  @import 'styles';
  @import '../../../../styles/news-filter';

  .hackIos {
    position: absolute;
    left: -10px;

    width: 1px;
    height: 1px;
    padding: 0;
    border: none;

    background: transparent;
    box-shadow: none;
    outline: none;

    -webkit-appearance: none;

    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .form {
    padding: $mobile-side-indent $mobile-padding 0;

    @include on-min-resize($tablet) {
      padding: $desk-side-indent $desktop-padding 16px;
    }
    @include on-min-resize($laptop) {
      display: flex;
      flex-direction: column;

      padding-bottom: 0;
    }
  }

  .result {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    padding: 0;

    color: $color-surface-500;

    .resultMessage {
      display: block;
      flex-grow: 1;

      padding: 0 0 $mobile-side-indent;

      font-size: 12px;
      line-height: 16px;

      @include on-min-resize($tablet) {
        padding: 0;
        font-size: 13px;
        line-height: 18px;
      }
      @include on-min-resize($laptop) {
        padding: 16px 0;
        border-top: 1px solid rgba($color-surface-200, 0.5);
      }
    }

    @include on-min-resize($laptop) {
      display: block;
      order: 1;

      border-top: none;
    }
  }

  .openBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-width: 100%;
    padding: $mobile-side-indent 0;
    border-top: 1px solid rgba($color-surface-200, 0.5);

    font-size: 10px;
    line-height: 13px;
    text-decoration: none;

    cursor: pointer;

    &.opened {
      .icon {
        color: $color-surface-400;
        transform: rotate(180deg);
      }
    }

    @include on-min-resize($tablet) {
      display: block;

      width: auto;
      min-width: auto;
      padding: 0;
      border: none;

      font-size: 17px;
      line-height: 22px;
    }
    @include on-min-resize($laptop) {
      display: none;
    }
  }

  .searchBox {
    position: relative;
    display: flex;

    margin-bottom: 12px;
    padding: 0 0 0 43px;

    background-color: rgba($color-surface-200, 0.24);

    @include on-min-resize($tablet) {
      margin-bottom: 16px;
    }

    .searchIcon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 30px;

      margin: auto;

      color: $color-surface-600;

      @include on-res($mobile) {
        left: 12px;
      }
    }

    .resetBtn {
      position: absolute;
      top: 0;
      right: 20px;
      bottom: 0;

      margin: auto;

      color: $color-surface-400;

      opacity: 0.55;

      @include on-res($mobile) {
        right: 13px;
      }
    }

    .inputSearch {
      @include font-stack('Roboto');

      display: block;

      width: 100%;
      padding: 8px 27px 9px 0;
      border: none;

      background: transparent;
      color: $color-surface-600;

      font-weight: bold;
      font-size: 13px;
      line-height: 27px;

      caret-color: initial;

      &:active,
      &:focus {
        border: none;

        outline: none;
      }

      @include on-min-resize($tablet) {
        padding: 28px 70px 28px 0;

        font-size: 28px;
      }
    }

    @include on-min-resize($tablet) {
      padding: 0 0 0 70px;
    }
  }

  .filter {
    position: relative;

    @include on-min-resize($tablet) {
      flex-wrap: wrap;

      width: calc(100% + 20px);
      margin: 0 -10px;
      padding: 0;
    }
    @include on-min-resize($laptop) {
      align-items: flex-start;
      justify-content: flex-start;

      margin: 0;
      padding: 0 0 16px;
    }
  }

  .filterItem {
    & + & {
      margin-top: 12px;

      @include on-min-resize($tablet) {
        margin-top: 20px;
      }
      @include on-min-resize($laptop) {
        margin-top: 0;
        margin-left: 20px;
      }
    }

    &.formSelect {
      width: 100%;

      @include on-min-resize($tablet) {
        flex-grow: 1;

        width: auto;
        min-width: 150px;
      }
      @include on-min-resize($laptop) {
        flex-grow: 0;

        width: 209px;
      }
    }

    &.placeholderDatepicker {
      height: 46px;
      border: 1px solid $color-surface-200;
      border-radius: 2px;

      background: #fff;

      @include on-min-resize($tablet) {
        width: 288px;
      }
      @include on-min-resize($laptop) {
        margin: 0;
      }
    }

    @include on-min-resize($tablet) {
      margin: 20px 10px 0;
    }
    @include on-min-resize($laptop) {
      margin: 0;
    }
  }

  .searchForm {
    .submitBtn {
      width: 100%;
      margin-top: 12px;

      @include on-min-resize($tablet) {
        flex-shrink: 0;

        width: 144px;
        margin: 20px 10px 0;
      }
      @include on-min-resize($laptop) {
        flex: initial;

        width: 139px;
        margin: 0 0 0 20px;
      }
    }
  }
</style>
