/* eslint-disable */
import { addUrlParams } from '@jtnews/shared/news';
import changeImagePlaceholder from '../../utils/changeImagePlaceholder';

const STRING_LOCALE = 'ru-RU';

export const RecordItemUtilsMixin = {
  computed: {
    $_RecordItemUtilsMixin_discussText() {
      return 'Обсудить';
    }
  },
  methods: {
    $_RecordItemUtilsMixin_getImageUrl(image) {
      return changeImagePlaceholder(image);
    },
    $_RecordItemUtilsMixin_getTheme(themes) {
      if (themes && themes.length > 0) {
        const obj = {
          url: themes[0].path,
          text: themes[0].name
        };
        return obj;
      }

      return null;
    },
    $_RecordItemUtilsMixin_getRubric(rubrics) {
      if (rubrics && rubrics.length > 0) {
        const obj = {
          url: rubrics[0].path,
          text: rubrics[0].name
        };
        return obj;
      }

      return null;
    },
    $_RecordItemUtilsMixin_getFormatName(formats) {
      if (formats) {
        return formats[0] ? formats[0].name : '';
      }

      return '';
    },
    $_RecordItemUtilsMixin_getFormatUrl(formats) {
      if (formats) {
        return formats[0] ? formats[0].url : '';
      }

      return '';
    },
    $_RecordItemUtilsMixin_getDiscussUrl(url) {
      return addUrlParams(url, { discuss: '1' });
    }
  }
};
