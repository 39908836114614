<template>
  <div :class="$style['search-page']">
    <app-layout :render-left-column="false">
      <template slot="central-column">
        <search-form
            :query="keywords"
            :count="totalResult"
            :sort="sort"
            :date="date"
            @onSearch="onSearch"
            @invalidData="invalidData"
        />
        <template v-if="showedResults">
          <div :class="$style['search-list']">
            <search-result-item
                v-for="searchItem of searchResult"
                :key="searchItem.id"
                :record="searchItem"
                :regionId="regionId"
                :is-in-bookmarks="checkIfInBookmarks(searchItem.id)"
                :is-mobile-only="isMobileOnly"
                @clickedBookmark="changeBookmarkStatus(searchItem.id)"
                @discussClicked="goToDiscuss"
            />
          </div>
          <jtn-ui-pagination
              v-model="pageData"
              v-show="pageData.numPages && pageData.numPages > 1"
              :class="$style['pagination']"
              :items-per-page="searchParams.pagesize"
              :total-items="totalResult"
              @change="changePage(pageData.currentPage)"
          />
        </template>
      </template>

      <template slot="right-column">
        <app-right-column/>
      </template>
    </app-layout>
    <bookmark-error-modal
      v-if="isBookmarkErrorModalRendered"
      v-model="isBookmarkErrorModalOpened"
    />
    <something-wrong-modal
        v-if="isSomethingWrongModalRendered"
        v-model="isSomethingWrongModalOpen"
    />

    <BlockedUserModal
      v-if="isBlockedUserModalRendered"
      v-model="isBlockedUserModalOpened"
    />

    <auth-and-register-modal
        v-if="isAuthModalRendered"
        v-model="isAuthModalOpen"
    />
  </div>
</template>

<script>
import { JtnUiPagination } from '@jtnews/jtn-ui';
import { PageAnalyticsMixin, WindowScrollMixin } from '@jtnews/shared';

import AppLayout from '../../../layout/containers/app-layout';
import AppRightColumn from '../../../layout/containers/app-right-column';
import SearchForm from '../components/search-form';
import SearchResultItem from '../components/search-result-item';

// eslint-disable-next-line @typescript-eslint/naming-convention
const BookmarkErrorModal = () =>
  import(/* webpackChunkName: "bookmark-error-modal" */ '@jtnews/layout/modals').then(m => m.BookmarkErrorModal);

// eslint-disable-next-line @typescript-eslint/naming-convention
const SomethingWrongModal = () =>
    import(/* webpackChunkName: "something-wrong-modal" */ '@jtnews/layout/modals').then(m => m.SomethingWrongModal);

const BlockedUserModal = () =>
  import(/* webpackChunkName: "blocked-user-modal" */ '@jtnews/layout/modals').then(m => m.BlockedUserModal);

// eslint-disable-next-line @typescript-eslint/naming-convention
const AuthAndRegisterModal = () =>
    import(/* webpackChunkName: "auth-and-register-modal" */ '@jtnews/layout/modals').then(
        m => m.AuthAndRegisterModal
    );

const DEFAULT_SORT_PARAM = 'date';

export default {
  name: 'SearchPage',
  components: {
    JtnUiPagination,
    AppLayout,
    AppRightColumn,
    SearchForm,
    AuthAndRegisterModal,
    SearchResultItem,
    BookmarkErrorModal,
    SomethingWrongModal,
    BlockedUserModal
  },
  mixins: [WindowScrollMixin, PageAnalyticsMixin],
  data() {
    return {
      searchParams: {
        page: this.$route.query.page || 1,
        pagesize: 40,
        text: this.$route.query.keywords || '',
        sort: this.$route.query.sort || DEFAULT_SORT_PARAM,
        dateFrom: this.$route.query.dateFrom || undefined,
        dateTo: this.$route.query.dateTo || undefined
      },
      pageData: {
        currentPage: parseInt(this.$route.query.page, 10) || 1
      },
      keywords: this.$route.query.keywords,
      date: {
        min: this.$route.query.dateFrom || '',
        max: this.$route.query.dateTo || ''
      },
      sort: this.$route.query.sort || DEFAULT_SORT_PARAM,
      invalidFilters: false,
      isBookmarkProcessing: false,
      isBookmarkErrorModalOpened: false,
      isBookmarkErrorModalRendered: false,
      isSomethingWrongModalOpen: false,
      isSomethingWrongModalRendered: false,
      isBlockedUserModalOpened: false,
      isBlockedUserModalRendered: false,
      isAuthModalOpen: false,
      isAuthModalRendered: false,
      isAllowShowResults: false
    };
  },
  computed: {
    searchBlock() {
      return this.store.archivesModule.searchResultBlock;
    },
    searchResult() {
      if (this.searchBlock.data) {
        return this.searchBlock.data;
      }

      return [];
    },
    totalResult() {
      if (this.searchBlock.meta) {
        return this.searchBlock.meta.total;
      }

      return 0;
    },
    showedResults() {
      return this.isAllowShowResults
          && !this.invalidFilters
          && this.totalResult
          && this.searchResult.length > 0;
    },
    isBlockedUser() {
      return this.store.commonModule.isBlockedUser;
    },
    userInfo() {
      return this.store.commonModule.userInfo || null;
    },
    isBookmarkError() {
      return this.store.userModule.isBookmarkLimitError;
    },
    isMobileOnly() {
      return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
    }
  },
  mounted() {
    setTimeout(() => {
      this.isAllowShowResults = true;
    }, 100);
  },
  methods: {
    onSearch(params) {
      this.searchParams = {
        ...this.searchParams,
        ...params
      };

      const routerParams = this.setParams(this.$route.query, params);
      routerParams.keywords = params.text;

      this.updatePage(routerParams);
    },
    changePage(page) {
      this.searchParams = {
        ...this.searchParams,
        page
      };

      const routerParams = {
        ...this.$route.query,
        page: page > 1 ? page : undefined
      };

      this.updatePage(routerParams);
    },
    setParams(currentParams, newParams) {
      return {
        ...currentParams,
        page: newParams.page,
        sort: newParams.sort,
        dateFrom: newParams.dateFrom,
        dateTo: newParams.dateTo
      };
    },
    updatePage(params) {
      const forwardedUrl = new URL(`${location.origin}${location.pathname}`);
      for (const key in params) {
        if (params[key] !== undefined) {
          forwardedUrl.searchParams.append(key, params[key]);
        }
      }
      window.location.href = forwardedUrl.toString();
    },
    invalidData(value) {
      this.invalidFilters = value;
    },
    goToDiscuss({ event, url }) {
      if (this.isBlockedUser === true) {
        event.preventDefault();
        this.openBlockedUserModal();
      }
    },
    openBlockedUserModal() {
      this.isBlockedUserModalOpened = true;
      this.isBlockedUserModalRendered = true;
    },
    checkIfInBookmarks(recordId) {
      return this.store.userModule.recordsWithBookmark.includes(recordId);
    },
    async changeBookmarkStatus(recordId) {
      if (this.isBookmarkProcessing === true) {
        return;
      }

      const bookmarkId = this.store.userModule.bookmarkIdsByRecord[recordId] || '';

      if (this.userInfo === null) {
        this.store.userModule.sendBookmarkReachGoal({ recordId, bookmarkId, isAuthorized: false });
        this.showAuthModal();
        return;
      }

      this.isBookmarkProcessing = true;

      try {
        await this.store.userModule.changeBookmarkStatus({
          regionId: this.regionId,
          profileId: this.userInfo.userId,
          recordId,
          bookmarkId,
          type: 'record'
        });

        this.store.userModule.sendBookmarkReachGoal({ recordId, bookmarkId });
      } catch (error) {
        this.openModal();
      } finally {
        this.isBookmarkProcessing = false;
      }
    },
    openModal() {
      if (this.isBookmarkError) {
        this.openBookmarkErrorModal();
        return;
      }

      this.openErrorModal()
    },
    openBookmarkErrorModal() {
      this.isBookmarkErrorModalOpened = true;
      this.isBookmarkErrorModalRendered = true;
    },
    openErrorModal() {
      this.isSomethingWrongModalOpen = true;
      this.isSomethingWrongModalRendered = true;
    },
    showAuthModal() {
      this.isAuthModalRendered = true;
      this.isAuthModalOpen = true;
    },
    sendReachGoal(fieldType, valueName, goalName) {
      const blockType = 'Центральная колонка';
      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName
      });
    }
  }
};
</script>
<style lang="scss" module>
@import 'styles';

.search-page {
  display: block;
}

.search-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include on-min-resize($tablet) {
    padding: 0 $desktop-padding;
  }
}

.pagination {
  margin: 0 $mobile-padding;
  padding: 24px 0;
  border-top: 1px solid hsla(0, 0%, 83.9%, .5);

  @include on-min-resize($tablet) {
    margin: 0 $desktop-padding;
  }
}
</style>
