export const WindowScrollMixin = {
  computed: {
    fetching() {
      return this.$store.state.dataFetching;
    }
  },
  watch: {
    fetching() {
      if (!this.fetching) {
        this.$root.$emit('after-enter');
      }
    }
  }
};
