import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-select.styles.scss?module';

type SelectItem = {
  name: string;
  value?: string;
  id?: string;
};

type ComponentData = {
  selectedValue: string;
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiSelect',
  props: {
    selected: {
      type: String,
      default: ''
    },
    options: {
      type: Array as () => SelectItem[],
      default() {
        return [];
      }
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data(): ComponentData {
    return {
      selectedValue: ''
    };
  },
  watch: {
    selected() {
      this.selectedValue = this.selected;
    }
  },
  mounted() {
    this.selectedValue = this.selected;
  },
  render() {
    const listeners = {
      attrs: this.$attrs,
      on: this.$listeners
    };

    return (
      <div>
        {this.label !== '' && (
          <label for={this.id} class={styles.label}>
            <span class={styles.labelText}>
              {this.label}
              {this.required && <span class={styles.requiredMark}>{' *'}</span>}
            </span>
          </label>
        )}
        <div class={styles.selectFrame}>
          <select
            v-model={this.selectedValue}
            id={this.id}
            class={styles.select}
            {...listeners}
          >
            {this.options.map((item: SelectItem) => (
              <option key={item.name} value={item.value || item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <svg class={styles.icon} stroke-width={2} width={22} height={22}>
            <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-arrow-down"></use>
          </svg>
        </div>
      </div>
    );
  }
});
