import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';
import { JtnUiBtnIcon } from '../jtn-ui-btn-icon';

import styles from './jtn-ui-bookmark.style.scss?module';

interface Events {
  onClickedBookmark: void;
}

const ICON_SIZE = 16;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiBookmark',
    props: {
      isAdded: {
        type: Boolean,
        default: false
      },
      color: {
        type: String
      }
    },
    computed: {
      title(): string {
        return this.isAdded ? 'В закладках' : 'В закладки';
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs
      };

      return (
        <JtnUiBtnIcon
          class={[
            styles.bookmark,
            styles[this.color],
            this.isAdded ? styles.isAdded : ''
          ]}
          title={this.title}
          btnType="button-other-type-transparent"
          size={16}
          {...attributes}
          onClick={() => this.$emit('clickedBookmark')}
        >
          {this.isAdded ? (
            <svg width={ICON_SIZE} height={ICON_SIZE}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-bookmark-solid"></use>
            </svg>
          ) : (
            <svg width={ICON_SIZE} height={ICON_SIZE}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-bookmark-outline"></use>
            </svg>
          )}
        </JtnUiBtnIcon>
      );
    }
  });
