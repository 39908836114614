import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../mixins/inject-styles';
import { JtnUiBtnIcon } from '../../jtn-ui-btn-icon';

import styles from './jtn-ui-pagination.styles.scss?module';

interface Events {
  onChange: void;
  onInput: {
    currentPage: number;
    numPages?: number;
  };
}

type PaginateValue = {
  currentPage: number;
  numPages?: number;
};

type Page = {
  number: number;
  text: string;
  active: boolean;
};

type ComponentData = {
  currentPage: number;
  previousPageLink: string | undefined;
  nextPageLink: string | undefined;
};

// prev & next control btn sizes
const CTRL_BTN_WIDTH = 21;
const CTRL_BTN_HEIGHT = 35;
const CTRL_ICON_SIZE = 20;
const CTRL_ICON_STROKE = 2;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiPagination',
    props: {
      value: {
        type: Object as () => PaginateValue,
        require: true
      },
      totalItems: {
        type: Number,
        default: 0
      },
      itemsPerPage: {
        type: Number,
        default: 5
      },
      maxSize: {
        type: Number,
        default: 3
      },
      firstPageUrl: {
        type: String,
        default: ''
      }
    },
    data(): ComponentData {
      return {
        currentPage: this.value.currentPage,
        previousPageLink: undefined,
        nextPageLink: undefined
      };
    },
    computed: {
      noNext(): boolean {
        return this.value?.currentPage === this.totalPages;
      },
      noPrevious(): boolean {
        return this.value?.currentPage === 1;
      },
      totalPages(): number {
        const totalPages =
          this.itemsPerPage < 1 ? 1 : Math.ceil(this.totalItems / this.itemsPerPage);

        return Math.max(totalPages || 0, 1);
      },
      hasNativeNavigation(): boolean {
        return this.firstPageUrl.length > 0;
      },
      pages(): Page[] {
        const pages = [] as Page[];

        if (this.currentPage <= 0 || this.currentPage > this.totalPages) {
          return pages;
        }

        // Default page limits
        let startPage = 1;
        let endPage = this.totalPages;
        const isMaxSized = this.isDefined(this.maxSize) && this.maxSize;

        // recompute if maxSize
        if (isMaxSized < this.totalPages) {
          // Current page is displayed in the middle of the visible ones
          startPage = Math.max(this.currentPage - Math.floor(this.maxSize / 2), 1);
          endPage = startPage + this.maxSize - 1;

          // Adjust if limit is exceeded
          if (endPage > this.totalPages) {
            endPage = this.totalPages;
            startPage = endPage - this.maxSize + 1;
          }
        }

        // Add page number links
        for (let number = startPage; number <= endPage; number += 1) {
          const page = this.makePage(number, `${number}`, number === this.currentPage);
          pages.push(page);
        }

        // Add links to move between page sets
        if (isMaxSized < this.totalPages && this.maxSize > 0) {
          if (startPage > 1) {
            if (startPage > 3) {
              // need ellipsis for all options unless range is too close to beginning
              const previousPageSet = this.makePage(startPage - 1, '...', false);
              pages.unshift(previousPageSet);
            }

            if (startPage === 3) {
              // need to replace ellipsis when the buttons would be sequential
              const secondPageLink = this.makePage(2, '2', false);
              pages.unshift(secondPageLink);
            }

            // add the first page
            const firstPageLink = this.makePage(1, '1', false);
            pages.unshift(firstPageLink);
          }

          if (endPage < this.totalPages) {
            if (endPage < this.totalPages - 2) {
              // need ellipsis for all options unless range is too close to end
              const nextPageSet = this.makePage(endPage + 1, '...', false);
              pages.push(nextPageSet);
            }
            if (endPage === this.totalPages - 2) {
              // need to replace ellipsis when the buttons would be sequential
              const secondToLastPageLink = this.makePage(
                this.totalPages - 1,
                `${this.totalPages - 1}`,
                false
              );
              pages.push(secondToLastPageLink);
            }
            // add the last page
            const lastPageLink = this.makePage(
              this.totalPages,
              `${this.totalPages}`,
              false
            );
            pages.push(lastPageLink);
          }
        }

        // Emit the new data to the parent.
        this.$emit('input', this.createDataForModel(this.currentPage, this.totalPages));

        return pages;
      }
    },
    mounted() {
      this.getPreviousPageLink();
      this.getNextPageLink();
    },
    watch: {
      // eslint-disable-next-line
      'value.currentPage': function(value, oldValue) {
        if (value < 1) {
          this.currentPage = 1;
        } else {
          this.currentPage =
            value <= this.totalPages ? (value as number) : this.totalPages;
        }

        if (oldValue == null) {
          return;
        }

        this.$emit('change');
      }
    },
    methods: {
      isDefined(value: number) {
        return typeof value !== 'undefined';
      },
      selectPage(page: number, event: Event) {
        if (!this.hasNativeNavigation) {
          event.preventDefault();

          const clickAllowed = this.currentPage !== page && page > 0;

          if (clickAllowed && page <= this.totalPages) {
            if (event && event.target) {
              (event.target as HTMLElement).blur();
            }

            this.currentPage = page;
          }
        }
      },
      makePage(number: number, text: string, active: boolean) {
        return {
          number,
          text,
          active
        };
      },
      createDataForModel(currentPage: number, numPages: number) {
        const data = {
          currentPage,
          numPages
        };

        Object.defineProperties(data, {
          numPages: {
            get() {
              return numPages;
            }
          }
        });

        return data;
      },
      getPreviousPageLink() {
        this.previousPageLink =
          this.currentPage - 1 === 0
            ? undefined
            : this.currentPage - 1 === 1
            ? this.firstPageUrl
            : `?page=${this.currentPage - 1}`;
      },
      getNextPageLink() {
        this.nextPageLink =
          this.currentPage === this.totalPages
            ? undefined
            : `?page=${this.currentPage + 1}`;
      },
      getPageLink(page: number) {
        return page === 1 ? this.firstPageUrl : `?page=${page}`;
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs
      };
      return (
        <div
          class={styles.paginationWrap}
          {...attributes}
          data-test="pagination-component"
        >
          <JtnUiBtnIcon
            class={styles.btnLeft}
            url={this.hasNativeNavigation ? this.previousPageLink : undefined}
            disabled={this.noPrevious}
            btn-type="button-other-type-3"
            height={CTRL_BTN_HEIGHT}
            width={CTRL_BTN_WIDTH}
            data-test="pagination-btn-left"
            onClick={(event: Event) => {
              this.selectPage(this.currentPage - 1, event);
            }}
          >
            <svg
              stroke-width={CTRL_ICON_STROKE}
              width={CTRL_ICON_SIZE}
              height={CTRL_ICON_SIZE}
            >
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-arrow-right"></use>
            </svg>
          </JtnUiBtnIcon>
          <div class={styles.pagination}>
            {this.pages.map((page: Page, index: number) => (
              <div
                key={index}
                class={[styles.paginationPage, page.active ? styles.active : '']}
              >
                <a
                  href={
                    this.hasNativeNavigation ? this.getPageLink(page.number) : undefined
                  }
                  class={styles.paginationBtn}
                  data-test="pagination-btn-page"
                  domPropsInnerHTML={page.text}
                  onClick={(event: Event) => {
                    this.selectPage(page.number, event);
                  }}
                />
              </div>
            ))}
          </div>
          <JtnUiBtnIcon
            class={styles.btnRight}
            url={this.hasNativeNavigation ? this.nextPageLink : undefined}
            disabled={this.noNext}
            btn-type="button-other-type-3"
            height={CTRL_BTN_HEIGHT}
            width={CTRL_BTN_WIDTH}
            data-test="pagination-btn-right"
            onClick={(event: Event) => {
              this.selectPage(this.currentPage + 1, event);
            }}
          >
            <svg
              stroke-width={CTRL_ICON_STROKE}
              width={CTRL_ICON_SIZE}
              height={CTRL_ICON_SIZE}
            >
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-arrow-right"></use>
            </svg>
          </JtnUiBtnIcon>
        </div>
      );
    }
  });
