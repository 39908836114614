import {
  getResponsiveImageData,
  ImageDecoding,
  ImageLoading,
} from '@jtnews/shared/images';
import type { ImageSourceVM, ResponsiveImageValues } from '@jtnews/shared/images';

import type { Image } from '../../domain';

type ImageData = {
  src: string;
  sources: ImageSourceVM[];
  decoding?: ImageDecoding;
  loading?: ImageLoading;
};

const previewValues = [
  {
    breakpoint: 375,
    width: 343,
  },
  {
    breakpoint: 599,
    width: 556,
  },
  {
    breakpoint: 0,
    width: 240,
    noMedia: true,
  },
];

const getImageData = (input: {
  url: string;
  width: number;
  values: ResponsiveImageValues[];
  hasCommercialLabel: boolean;
}) => {
  const { url, width, values, hasCommercialLabel } = input;
  const info = getResponsiveImageData({
    url,
    width,
    values,
    hasCommercialLabel,
    isPreloadImage: false,
    isLazy: false,
  });

  return {
    src: info.data.src,
    sources: info.data.sources,
    decoding: info.data.decoding,
    loading: info.data.loading,
    hasCommercialLabel: info.data.hasCommercialLabel,
  };
};

export type ArchiveItemImage = Image & {
  previewImage: ImageData;
};

export const createArchiveItemImage = (image: Image): ArchiveItemImage => {
  return {
    ...image,
    previewImage: getImageData({
      url: image.url,
      width: image.width,
      values: previewValues,
      hasCommercialLabel: image.hasCommercialLabel,
    }),
  };
};
