/* eslint-disable */

import { useStore } from 'vuex-simple';

import { isSameRegion, Regions } from '@smh/projects/regions';

import { PagesType, PAGE_ANALYTICS_NAME } from '@jtnews/shared/data';

const BOUNCE_TIMEOUT = 15000;

/**
 * @deprecated использовать pageAnalyticsMixinTs
 */
export const PageAnalyticsMixin = {
  data() {
    return {
      store: useStore(this.$store)
    };
  },
  computed: {
    regionId() {
      return this.store.regionId;
    },
    pageType() {
      return this.store.pageType;
    },
    metatags() {
      return this.store.metatags;
    },
    commonModule() {
      return this.store.commonModule || {};
    },
    pageReachGoals() {
      const reachGoals = this.commonModule.commonReachGoal;
      return reachGoals ? reachGoals.data : [];
    },
    pageGAEvents() {
      const gaEvents = this.commonModule.commonGAEvents;
      return gaEvents ? gaEvents.data : [];
    },
    hasPerformanceMetricsCollection() {
      return (
        isSameRegion(this.regionId, Regions.Krasnoyarsk)
      );
    }
  },
  beforeMount() {
    if (this.hasPerformanceMetricsCollection) {
      this.store.commonModule.startCollectPerformanceMetrics();
    }
  },
  mounted() {
    this.sendPageAnalyticsData();

    if (this.hasPerformanceMetricsCollection) {
      window.addEventListener('beforeunload', this.sendPageMetricsData);
    }
  },
  beforeDestroy() {
    if (this.hasPerformanceMetricsCollection) {
      window.removeEventListener('beforeunload', this.sendPageMetricsData);
    }
  },
  methods: {
    sendPageAnalyticsData() {
      const analyticsData = this.getPageAnalyticsData();

      if (this.pageType === PagesType.Record || this.pageType === PagesType.Longread) {
        analyticsData.recordData = this.getRecordAnalyticsData();
      }

      // сначала отправляем общую аналитику
      this.store.analyticsModule.sendPageAnalyticsData(analyticsData);

      // потом отправляет глобальные рич-голы для страницы
      if (this.pageReachGoals && this.pageReachGoals.length > 0) {
        this.pageReachGoals.forEach(reachGoal => {
          const { name, params } = reachGoal;

          this.store.analyticsModule.sendReachGoal({ name, params });
        });
      }

      // TODO: отрефакторить - сделать единый сервис для обработки эвентов; пока что это костыль для REGIONNEWS-15627
      let globalCategory;
      if (analyticsData.recordData && analyticsData.recordData.formats) {
        if (
          analyticsData.recordData.formats.findIndex(
            format => format.name === 'Мнение'
          ) !== -1
        ) {
          globalCategory = 'Материал-Мнение';
        }
      }

      setTimeout(() => {
        this.store.analyticsModule.sendReachGoal({ name: 'notBounce' });
      }, BOUNCE_TIMEOUT);

      this.pageAnalyticsDataSent();
    },
    // метод, который вызывается после каждой отправки аналитики,
    // чтобы в самом компоненте можно было сделать какие-нибудь действия
    // после отправки аналитики
    // eslint-disable-next-line
    pageAnalyticsDataSent() {},
    getPageAnalyticsData() {
      return {
        href: window.location.href,
        pathname: window.location.pathname,
        search: window.location.search,
        canonicalUrl: `${this.metatags.canonical}`,
        title: this.metatags.title,
        language: 'ru',
        type: PAGE_ANALYTICS_NAME[this.pageType]
      };
    },
    getRecordAnalyticsData() {
      let analyticsData;
      const recordBlock = this.store.layoutModule.record;
      if (recordBlock && recordBlock.data) {
        const recordData = recordBlock.data;
        let authors = [];
        if (recordData.authors?.length > 0) {
          authors = recordData.authors.map(a => a.name);
        }

        let categories = [];
        if (recordData.isCommercial) {
          categories = ['РЕКЛАМА'];
        } else {
          const { rubrics } = recordData;
          if (rubrics && rubrics.length > 0) {
            categories = rubrics.map(r => r.name);
          }
        }

        let formats = [];
        if (recordData.formats && recordData.formats.length > 0) {
          formats = [...recordData.formats];
        }

        const type = recordData.analyticsType;
        const recordId = recordData.id;
        const publicationDate = recordData.rfc822PublishAt;
        const header = recordData.header;

        return {
          recordId,
          authors,
          categories,
          type,
          publicationDate,
          formats,
          header
        };
      }

      return analyticsData;
    },
    sendPageMetricsData() {
      this.store.commonModule.sendPagePerformanceMetrics();
    }
  }
};
