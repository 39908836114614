/* eslint-disable @typescript-eslint/unbound-method */
import { guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';
import DatePicker from 'vue2-datepicker';

// eslint-disable-next-line import/no-unassigned-import
import 'vue2-datepicker/locale/ru';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-datepicker.styles.scss?module';
// eslint-disable-next-line import/no-unassigned-import
import './vue2-datepicker.styles.scss';

type ComponentData = {
  value: DateValue;
};

type DateValue = string | null | (string | null)[];

interface Events {
  onChangedPeriod: (string | null)[];
  onChangedValue: string | null;
  onMounted: void;
}

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiDatepicker',
    props: {
      id: {
        type: String,
        default: ''
      },
      date: {
        type: String,
        default: ''
      },
      dates: {
        type: Array as () => (string | null)[],
        default: (): [] => []
      },
      label: {
        type: String,
        default: ''
      },
      disabledAfterToday: {
        type: Boolean,
        default: false
      },
      isRange: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      valueToken: {
        type: String,
        default: 'DD.MM.YYYY'
      },
      format: {
        type: String,
        default: 'DD.MM.YYYY'
      }
    },
    data(): ComponentData {
      return {
        value: null
      };
    },
    mounted() {
      this.value = this.isRange ? this.dates : this.date;
      this.$emit('mounted');
    },
    methods: {
      onChanged(value: DateValue) {
        this.value = value;
        this.$emit(this.isRange ? 'changedPeriod' : 'changedValue', value);
      },
      disableDateRange(date: Date) {
        if (this.disabledAfterToday) {
          const today = new Date();
          return date > today;
        }
        return false;
      },
      openEventListener(): void {
        if (this.isRange) {
          setTimeout(() => {
            this.moveMonthTrigger();
          }, 0);
        }
      },
      // костыль, переключаем обе панели пикера на 1 месяц назад
      moveMonthTrigger(): void {
        if (guardUnspecified(this.value) && this.value.length === 0) {
          const buttons = document.querySelectorAll(
            '.vue2-datepicker-popup .vue2-btn-icon-left'
          );

          buttons.forEach(btn => (btn as HTMLElement).click());
        }
      },
      clearEventListener(): void {
        this.value = this.isRange ? [] : '';
        this.$emit(this.isRange ? 'changedPeriod' : 'changedValue', this.value);
      }
    },
    render() {
      const ICON_WIDTH = 14;

      return (
        <div class={styles.datepickerWrap}>
          {this.label.length > 0 && (
            <label for={this.id} class={styles.label}>
              {this.label}
            </label>
          )}

          <DatePicker
            value={this.value}
            editable={false}
            range={this.isRange}
            placeholder={this.placeholder}
            valueType={this.valueToken}
            format={this.format}
            prefixClass="vue2"
            rangeSeparator=" — "
            titleFormat={this.valueToken}
            disabledDate={(date: Date) => this.disableDateRange(date)}
            onInput={(e: DateValue) => this.onChanged(e)}
            onOpen={() => this.openEventListener()}
            onClear={() => this.clearEventListener()}
          >
            <svg slot="icon-calendar" width={ICON_WIDTH} height="16">
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-calendar"></use>
            </svg>
            <svg slot="icon-clear" width={ICON_WIDTH} height="10">
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
            </svg>
          </DatePicker>
        </div>
      );
    }
  });
