import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-date.style.scss?module';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiDate',
  props: {
    date: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    type: {
      type: String as () => 'default' | 'uppercase',
      default: 'default'
    }
  },
  render() {
    const attributes = {
      attrs: this.$attrs
    };
    return (
      <time class={[styles.date, styles[this.type]]} {...attributes}>
        {this.link ? <a href={this.link}>{this.date}</a> : <span>{this.date}</span>}
      </time>
    );
  }
});
