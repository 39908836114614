<template>
  <jtn-ui-search-archive-item
    :url-title="removeTags(record.header)"
    :url="record.urls.url"
    :target-url="getTargetLink(record.urls.url)"
    :header="record.header"
    :has-video-mark="record.hasVideoMark"
    :is-mobile-only="isMobileOnly"
    :subheader="record.subheader"
    :image="getImage(record.mainPhoto)"
  >
    <template v-slot:format>
      <jtn-ui-typography v-if="format" :type="''" v-bind="formatComponent">
        {{ format.name }}
      </jtn-ui-typography>
    </template>

    <template v-slot:theme>
      <jtn-ui-typography
        v-if="theme"
        tag="a"
        :type="''"
        :href="theme.path"
        :title="theme.name"
      >
        {{ theme.name }}
      </jtn-ui-typography>
    </template>

    <template v-slot:rubrics>
      <jtn-ui-typography
        v-for="rubric of rubrics"
        :key="rubric.id"
        tag="a"
        :type="''"
        :href="rubric.path"
        :title="rubric.name"
      >
        {{ rubric.name }}
      </jtn-ui-typography>
    </template>

    <template v-slot:stats>
      <jtn-ui-record-statistics-will-deprecate
        :views="record.viewsCount"
        :comments="record.commentsCount"
        :comments-url="record.urls.urlComments"
        :comments-enabled="record.isCommentsAllowed"
        :discuss-text="$_RecordItemUtilsMixin_discussText"
        :discuss-url="$_RecordItemUtilsMixin_getDiscussUrl(record.urls.urlComments)"
        :target-url="getTargetLink(record.urls.urlComments)"
        @discuss="
          goToDiscuss(
            $event,
            $_RecordItemUtilsMixin_getDiscussUrl(record.urls.urlComments)
          )
        "
      />
    </template>

    <template v-slot:date>
      <date-info
        v-if="regionId"
        :date="record.publishAt"
        :region-id="regionId"
        :is-with-link="true"
      />
    </template>

    <jtn-ui-bookmark
      slot="bookmark"
      :is-added="isInBookmarks"
      @clickedBookmark="emitClickedBookmark"
    />
  </jtn-ui-search-archive-item>
</template>
<script>
import { createArchiveItemImage } from '@jtnews/shared/news';
import { guardUnspecified } from '@portal/utils/util-guards';
import { isAbsoluteUrl } from '@portal/utils/util-url';
import { JtnUiRecordStatisticsWillDeprecate } from '@jtn-ui/components/record'
import {
  JtnUiTypography,
  JtnUiSearchArchiveItem,
  JtnUiBookmark
} from '@jtnews/jtn-ui';
import { RecordItemUtilsMixin, DateInfo } from '@jtnews/shared';

export default {
  name: 'SearchResultItem',
  components: {
    JtnUiSearchArchiveItem,
    JtnUiRecordStatisticsWillDeprecate,
    JtnUiTypography,
    JtnUiBookmark,
    DateInfo
  },
  mixins: [RecordItemUtilsMixin],
  props: {
    record: {
      type: Object,
      default() {
        return {};
      }
    },
    regionId: {
      type: Number,
      default: 0
    },
    isInBookmarks: {
      type: Boolean,
      default: false
    },
    isMobileOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    format() {
      if (this.record.formats && this.record.formats.length > 0) {
        return this.record.formats[0];
      }
      return false;
    },
    rubrics() {
      if (this.record.rubrics && this.record.rubrics.length > 0) {
        return this.record.rubrics;
      }
      return false;
    },
    theme() {
      if (this.record.themes.length > 0) {
        return this.record.themes[0];
      }
      return false;
    },
    formatComponent() {
      if (this.format.url) {
        return {
          tag: 'a',
          href: this.format.url,
          title: this.format.name
        };
      }

      return {
        tag: 'span'
      };
    },
  },
  methods: {
    removeTags(value) {
      // eslint-disable-next-line
      return value.replace(/(\<(\/?[^>]+)>)/g, '');
    },
    formatNumber(number) {
      return number.toLocaleString('ru-RU');
    },
    goToDiscuss(event, url) {
      this.$emit('discussClicked', { event, url });
    },
    emitClickedBookmark() {
      this.$emit('clickedBookmark');
    },
    getTargetLink(path) {
      return isAbsoluteUrl(path) ? '_blank' : '_self';
    },
    getImage(img) {
      return guardUnspecified(img) ? createArchiveItemImage(img).previewImage : null;
    }
  }
};
</script>
