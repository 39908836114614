export default (image /* : any */, crop /* : boolean */ = true) /* : string */ => {
  const replacePattern /* : string */ = '##';

  if (!image || !image.url) {
    return '';
  }

  const url = image.url;

  if (url.indexOf(replacePattern) === -1) {
    return url;
  }

  return url.replace(replacePattern, `_%width%_%height%${crop ? '_c' : ''}.`);
};
