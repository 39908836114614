/**
 * @deprecated По причине полного редизайна. Новый компонент находится по пути packages/jtn-ui/src/components/_record/jtn-ui-record-statistics/jtn-ui-record-statistics.component.tsx
 */

import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../../mixins/inject-styles';
import { JtnUiTypography } from '../../../jtn-ui-typography';

import styles from './jtn-ui-record-statistics.styles.scss?module';

interface Events {
  onCommentsClicked: Event;
  onDiscuss: Event;
}

const ICON_SIZE = 16;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiRecordStatisticsWillDeprecate',
    props: {
      views: {
        type: [Number, String],
        default: 0
      },
      comments: {
        type: [Number, String],
        default: 0
      },
      commentsUrl: {
        type: String,
        default: ''
      },
      commentsEnabled: {
        type: Boolean,
        default: true
      },
      hasDiscussLink: {
        type: Boolean,
        default: false
      },
      discussUrl: {
        type: String,
        default: ''
      },
      discussText: {
        type: String,
        default: 'Обсудить'
      },
      publishAt: {
        type: String,
        default: ''
      },
      isWhiteStyle: {
        type: Boolean,
        default: false
      },
      targetUrl: {
        type: String as () => '_self' | '_blank',
        default: '_self'
      }
    },
    computed: {
      commentsEvent(): string {
        return this.comments > 0 ? 'commentsClicked' : 'discuss';
      },
      commentsLink(): string {
        return this.comments > 0 ? this.commentsUrl : this.discussUrl;
      },
      showDiscussLink(): boolean {
        return this.commentsEnabled && this.hasDiscussLink && this.comments > 0;
      },
      hasViews(): boolean {
        return (
          this.views !== undefined && this.views !== null && Number(this.views) !== 0
        );
      }
    },
    methods: {
      formatNumber(number: number | string) {
        return number.toLocaleString('ru-RU');
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs
      };

      return (
        <div class={styles.recordStatistics} {...attributes}>
          {this.hasViews && (
            <div class={styles.item} title="просмотры" data-test="record-stats-view">
              <svg width={ICON_SIZE} height={ICON_SIZE} stroke-width={1.5}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-eye-small"></use>
              </svg>

              <span class={styles.text}>{this.formatNumber(this.views)}</span>
            </div>
          )}

          {this.commentsEnabled && (
            <a
              class={[styles.item, styles.comments, styles.link]}
              href={this.commentsLink}
              title="комментарии"
              target={this.targetUrl}
              onClick={(event: Event) => {
                this.$emit(this.commentsEvent, event);
              }}
            >
              <svg class={styles.commentsIcon} width={15} height={12}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-comments"></use>
              </svg>
              {this.comments > 0 ? (
                <span class={styles.text} data-test="record-stats-comments-count">
                  {this.formatNumber(this.comments)}
                </span>
              ) : (
                <span
                  class={[styles.text, styles.discuss]}
                  data-test="record-stats-comments-discuss"
                >
                  {this.discussText}
                </span>
              )}
            </a>
          )}

          {this.showDiscussLink && (
            <a
              class={[styles.item, styles.discuss]}
              href={this.discussUrl}
              target={this.targetUrl}
              data-test="record-stats-discuss"
              onClick={(event: Event) => {
                this.$emit('discuss', event);
              }}
            >
              {this.discussText}
            </a>
          )}

          {this.publishAt && (
            <JtnUiTypography
              tag="span"
              type="font-vijet-type-2"
              data-test="record-stats-publish"
            >
              {this.publishAt}
            </JtnUiTypography>
          )}
        </div>
      );
    }
  });
