import { dateFormat } from '@portal/utils/util-date';
import type { DateType } from '@portal/utils/util-date';
import { getCityByRegionId } from '@smh/projects/regions';
import { componentFactory } from 'vue-tsx-support';

import { JtnUiDate } from '@jtnews/jtn-ui';
import { DEFAULT_DATE_TIME_FORMAT, DEFAULT_DATE_FORMAT } from '@jtnews/shared/data';

const getFormattedDate = (date: DateType, format: string): string => {
  try {
    return dateFormat(date, format);
  } catch (e) {
    return '';
  }
};

export default componentFactory.create({
  name: 'DateInfo',
  props: {
    date: {
      type: String,
      required: true as const
    },
    regionId: {
      type: Number,
      required: true as const
    },
    formatDate: {
      type: String,
      default: DEFAULT_DATE_TIME_FORMAT
    },
    isWithLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dateFormat(): string {
      return getFormattedDate(this.date, this.formatDate);
    },
    dateLink(): string {
      const dateLink = getFormattedDate(this.date, 'YYYY/MM/DD');
      return `/text/${dateLink}/`;
    },
    title(): string {
      const date = getFormattedDate(this.date, DEFAULT_DATE_FORMAT);
      return `Все новости города ${getCityByRegionId(this.regionId)} за ${date}`;
    }
  },
  render() {
    return (
      <JtnUiDate
        date={this.dateFormat}
        link={this.isWithLink ? this.dateLink : ''}
        alt={this.title}
        title={this.title}
        datetime={this.date}
      />
    );
  }
});
